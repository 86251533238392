import { useState, useRef, useEffect } from 'react';
import {
  MapPin,
  ArrowLeftRight,
  Compass,
  Send,
  UserRound,
  Bot,
  Brain,
  X,
} from 'lucide-react';
import styles from './agent.module.css';
import { Box } from '@mui/material';
import Header from '../../layout/Header';
import MarkdownRenderer from './components/MarkdownRenderer';
import Feature from './components/Feature';

export default function Agent() {
  const [showChat, setShowChat] = useState(false);
  const [address, setAddress] = useState('');
  const [messages, setMessages] = useState([]);
  const [sideBarState, setSideBarState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedMaps, setSelectedMaps] = useState(['discern']);
  const dropdownRef = useRef(null);
  const chatInputRef = useRef(null);
  const [chatInputHeight, setChatInputHeight] = useState(0);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (chatInputRef.current) {
      setChatInputHeight(chatInputRef.current.offsetHeight);
    }
  }, [showChat]);

  const handleSubmit = async (e, input) => {
    e.preventDefault();
    const message = input || address;
    if (!message.trim()) return;

    let prompt = message;
    if (selectedMaps.includes('discern')) {
      prompt = `Get the DISCERN data for this address: ${message}`;
    }

    if (!showChat) {
      setShowChat(true);
      setMessages([{ role: 'user', content: message }]);
    } else {
      setMessages(prev => [...prev, { role: 'user', content: message }]);
    }

    setIsLoading(true);
    try {
      const response = await fetch(
        'https://appchefs.app.n8n.cloud/webhook/dae0b2ba-3b87-4c9d-a077-c182b4b9dfd4',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            chatInput: prompt,
          }),
        },
      );

      const data = await response.json();
      setMessages(prev => [
        ...prev,
        {
          role: 'assistant',
          content: data[0].output,
        },
      ]);
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prev => [
        ...prev,
        {
          role: 'assistant',
          content: 'Sorry, I encountered an error processing your request.',
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <Box
        sx={{
          paddingX: {
            md: '1.25rem',
            xs: '0',
          },
          marginBottom: '1.25rem',
        }}
      >
        <Header sideBarState={setSideBarState} pageTitle='Vestmap Agent' />
      </Box>

      {showChat ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '1rem',
              borderBottom: '1px solid #e5e7eb',
            }}
          >
            <X
              style={{
                cursor: 'pointer',
              }}
              onClick={() => setShowChat(false)}
            />
          </div>
          <div
            className={styles['chat-container']}
            style={{
              height: 'calc(100vh - 1rem)',
              paddingBottom: `${chatInputHeight}px`,
            }}
          >
            <div
              className={styles['chat-main']}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                className={styles['chat-messages']}
                style={{ flex: 1, overflowY: 'auto', padding: '20px' }}
              >
                {messages.map((message, i) => (
                  <div
                    key={i}
                    className={`${styles.message} ${
                      message.role === 'user'
                        ? styles['message--user']
                        : styles['message--assistant']
                    }`}
                  >
                    <div className={styles['message__content']}>
                      {message.role === 'assistant' && (
                        <div className={styles.avatar}>
                          <Bot
                            size={24}
                            style={{
                              color: 'white',
                            }}
                          />
                        </div>
                      )}
                      <div className={styles['message__bubble']}>
                        {message.role === 'assistant' ? (
                          <MarkdownRenderer text={message.content} />
                        ) : (
                          message.content
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {isLoading && (
                  <div className={styles.message}>
                    <div className={styles['message__content']}>
                      <div className={styles.avatar}>
                        <Bot
                          size={24}
                          style={{
                            color: 'white',
                          }}
                        />
                      </div>
                      <div className={styles['typing-indicator']}>
                        <div className={styles['typing-indicator__dot']}></div>
                        <div className={styles['typing-indicator__dot']}></div>
                        <div className={styles['typing-indicator__dot']}></div>

                        <div
                          style={{
                            left: '50%',
                            fontSize: '0.875rem',
                            color: '#6b7280',
                          }}
                        >
                          {(() => {
                            const messages = [
                              'Researching location details...',
                              'Analyzing market trends...',
                              'Evaluating property values...',
                              'Checking demographics...',
                              'Reviewing economic indicators...',
                            ];
                            const index = Math.floor(
                              (Date.now() / 2000) % messages.length,
                            );
                            return messages[index];
                          })()}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div ref={messagesEndRef} />
              </div>

              {/* Chat Input */}
              <div
                ref={chatInputRef}
                style={{
                  width: '100%',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    maxWidth: '50rem',
                    margin: '0 auto',
                    padding: '1.5rem',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: 'white',
                      borderRadius: '0.75rem',
                      boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.1)',
                      padding: '1rem',
                    }}
                  >
                    {/* Address Examples */}
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '0.5rem',
                        marginBottom: '1rem',
                      }}
                    >
                      {[
                        '1234 Oak Street, Denver, CO 80202',
                        '567 Pine Avenue, Boulder, CO 80301',
                        '890 Maple Drive, Fort Collins, CO 80525',
                      ].map(address => (
                        <div
                          key={address}
                          style={{
                            padding: '0.5rem 0.5rem',
                            fontSize: '0.875rem',
                            borderRadius: '9999px',
                            border: '1px solid #e5e7eb',
                            cursor: 'pointer',
                          }}
                        >
                          {address}
                        </div>
                      ))}
                    </div>

                    {/* Address Input */}
                    <div style={{ position: 'relative' }}>
                      <input
                        type='text'
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                        placeholder='Please input a valid street address'
                        style={{
                          boxSizing: 'border-box',
                          width: '100%',
                          paddingLeft: '1rem',
                          height: '3.25rem',
                          paddingRight: '3rem',
                          paddingTop: '0.75rem',
                          paddingBottom: '0.75rem',
                          border: '1px solid #e5e7eb',
                          borderRadius: '9999px',
                          outline: 'none',
                        }}
                        onFocus={e => (e.target.style.borderColor = '#40c4aa')}
                        onBlur={e => (e.target.style.borderColor = '#e5e7eb')}
                      />
                      <div
                        onClick={e => handleSubmit(e, address)}
                        style={{
                          position: 'absolute',
                          right: '0.5rem',
                          top: '0.3rem',
                          padding: '0.5rem',
                          backgroundColor: '#40c4aa',
                          color: 'white',
                          borderRadius: '9999px',
                          transition: 'background-color 0.2s',
                          cursor: 'pointer',
                        }}
                      >
                        <Send style={{ width: '1.5rem', height: '1.5rem' }} />
                      </div>
                    </div>

                    {/* DeepThink Option */}
                    <div
                      style={{
                        marginTop: '1rem',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem',
                          backgroundColor: '#f8f0fe',
                          color: '#8e24cc',
                          padding: '0.5rem 1rem',
                          borderRadius: '9999px',
                          fontSize: '0.875rem',
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: '#f3e8fd',
                          },
                        }}
                      >
                        <Brain />
                        DeepThink
                      </Box>
                      <p style={{ color: '#6b7280', fontSize: '0.875rem' }}>
                        Enable DeepThink for an advanced experience and to ask
                        follow ups. DeepThink may make mistakes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Box
          sx={{
            paddingX: {
              md: '12rem',
              xs: '0',
            },
            marginBottom: '1.25rem',
          }}
        >
          <div className={styles.features}>
            <Feature
              title='Analyze locations'
              icon={
                <MapPin
                  className={`${styles['feature__icon']} ${styles['feature__icon--active']}`}
                />
              }
              isActive
            />
            <Feature
              title='Compare locations'
              icon={<ArrowLeftRight className={styles['feature__icon']} />}
              isComingSoon
            />
            <Feature
              title='Discover new locations'
              icon={<Compass className={styles['feature__icon']} />}
              isComingSoon
            />
          </div>

          <form onSubmit={handleSubmit} className={styles['address-form']}>
            <input
              value={address}
              onChange={e => setAddress(e.target.value)}
              placeholder='Enter a Valid Street Address'
              className={styles['address-form__input']}
            />
            {/* Update the map selection section */}
            <div className={styles['map-selection']} ref={dropdownRef}>
              <label className={styles['map-selection__label']}>
                Select additional maps
              </label>
              <button
                type='button'
                className={styles['map-selection__button']}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                {selectedMaps.length} map{selectedMaps.length !== 1 ? 's' : ''}{' '}
                selected
              </button>
              {isDropdownOpen && (
                <div className={styles['map-selection__dropdown']}>
                  <div className={styles['map-selection__section']}>
                    <h4 className={styles['map-selection__section-title']}>
                      Map Options
                    </h4>
                    <label className={styles['map-selection__option']}>
                      <input
                        type='checkbox'
                        checked={selectedMaps.includes('discern')}
                        onChange={() => {
                          if (selectedMaps.includes('discern')) {
                            setSelectedMaps(
                              selectedMaps.filter(m => m !== 'discern'),
                            );
                          } else {
                            setSelectedMaps([...selectedMaps, 'discern']);
                          }
                        }}
                        className={styles['map-selection__checkbox']}
                      />
                      <span>DISCERN (default maps)</span>
                    </label>
                    <label className={styles['map-selection__option']}>
                      <input
                        type='checkbox'
                        checked={selectedMaps.includes('household')}
                        onChange={() => {
                          if (selectedMaps.includes('household')) {
                            setSelectedMaps(
                              selectedMaps.filter(m => m !== 'household'),
                            );
                          } else {
                            setSelectedMaps([...selectedMaps, 'household']);
                          }
                        }}
                        className={styles['map-selection__checkbox']}
                      />
                      <span>Household formation forecast</span>
                    </label>
                    <label className={styles['map-selection__option']}>
                      <input
                        type='checkbox'
                        checked={selectedMaps.includes('growth')}
                        onChange={() => {
                          if (selectedMaps.includes('growth')) {
                            setSelectedMaps(
                              selectedMaps.filter(m => m !== 'growth'),
                            );
                          } else {
                            setSelectedMaps([...selectedMaps, 'growth']);
                          }
                        }}
                        className={styles['map-selection__checkbox']}
                      />
                      <span>Unit growth vs. population growth</span>
                    </label>
                    <label className={styles['map-selection__option']}>
                      <input
                        type='checkbox'
                        checked={selectedMaps.includes('heatmap')}
                        onChange={() => {
                          if (selectedMaps.includes('heatmap')) {
                            setSelectedMaps(
                              selectedMaps.filter(m => m !== 'heatmap'),
                            );
                          } else {
                            setSelectedMaps([...selectedMaps, 'heatmap']);
                          }
                        }}
                        className={styles['map-selection__checkbox']}
                      />
                      <span>Rent Heatmap</span>
                    </label>
                    <label className={styles['map-selection__option']}>
                      <input
                        type='checkbox'
                        checked={selectedMaps.includes('floodzone')}
                        onChange={() => {
                          if (selectedMaps.includes('floodzone')) {
                            setSelectedMaps(
                              selectedMaps.filter(m => m !== 'floodzone'),
                            );
                          } else {
                            setSelectedMaps([...selectedMaps, 'floodzone']);
                          }
                        }}
                        className={styles['map-selection__checkbox']}
                      />
                      <span>Floodzone</span>
                    </label>
                  </div>
                  <div className={styles['map-selection__section']}>
                    <h4 className={styles['map-selection__section-title']}>
                      Custom
                    </h4>
                    <div className={styles['map-selection__search']}>
                      <input
                        type='text'
                        placeholder='Search for a data point'
                        className={styles['map-selection__search-input']}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <button type='submit' className={styles['address-form__button']}>
              VESTMAP IT
            </button>
          </form>
        </Box>
      )}
    </div>
  );
}
