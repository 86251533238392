import React from 'react';
import ReactMarkdown from 'react-markdown';

function MarkdownRenderer({ text }) {
  return (
    <div
      style={{
        maxWidth: '100%',
        lineHeight: 1.5,
        color: '#757575',
      }}
    >
      <ReactMarkdown>{text}</ReactMarkdown>
    </div>
  );
}

export default MarkdownRenderer;
