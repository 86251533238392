import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import AuthProvider from './AuthProvider';
import Layout from './Layout';
import ProtectedRoute from './ProtectedRoute';

import Home from './components/pages/Home';
import NotFound from './components/pages/NotFound';
import RunReports from './components/pages/RunReports';
import CrimeExplore from './components/pages/CrimeExplore';
import ExpansionExplorer from './components/pages/ExpansionExplorer';
import Zipfinder from './components/pages/Zipfinder';
import AdminPage from './components/pages/AdminPanel/AdminPage';
import BulkImport from './components/pages/BulkVestmaps';
import PlaceHolderPage from './components/pages/PlaceHolderPage';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import ReportTemplates from './components/pages/ReportTemplates';
import TestGround from './components/pages/TestGround';
import PublicReport from './components/pages/PublicReport';
import UserView from './components/pages/AdminPanel/UserView';
import Agent from './components/pages/Agent';

import './App.css';

const theme = createTheme({
  typography: {
    fontFamily: `'Lato', 'sans-serif'`,
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        <AuthProvider>
          <Layout>
            <Routes>
              {/* <Route path='/' element={<Home />} /> */}
              <Route
                path='/'
                element={
                  <ProtectedRoute>
                    <RunReports />
                  </ProtectedRoute>
                }
              />

              <Route
                path='/expansion-explorer'
                element={
                  <ProtectedRoute>
                    <ExpansionExplorer />
                  </ProtectedRoute>
                }
              />

              <Route
                path='/crime-explorer'
                element={
                  <ProtectedRoute>
                    <CrimeExplore />
                  </ProtectedRoute>
                }
              />

              <Route
                path='/zipfinder'
                element={
                  <ProtectedRoute>
                    <Zipfinder />
                  </ProtectedRoute>
                }
              />

              <Route
                path='/run-reports'
                element={
                  <ProtectedRoute>
                    <RunReports />
                  </ProtectedRoute>
                }
              />

              <Route
                path='/bulk-import'
                element={
                  <ProtectedRoute>
                    <BulkImport />
                  </ProtectedRoute>
                }
              />

              <Route
                path='/admin-panel'
                element={
                  <ProtectedRoute>
                    <AdminPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path='/in-progress'
                element={
                  <ProtectedRoute>
                    <PlaceHolderPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path='/templates'
                element={
                  <ProtectedRoute>
                    <ReportTemplates />
                  </ProtectedRoute>
                }
              />

              <Route
                path='/agent'
                element={
                  <ProtectedRoute>
                    <Agent />
                  </ProtectedRoute>
                }
              />

              <Route path='/report/:reportID' element={<PublicReport />} />
              <Route
                path='/user-view/:userID'
                element={
                  <ProtectedRoute>
                    <UserView />
                  </ProtectedRoute>
                }
              />

              <Route path='*' element={<NotFound />} />

              {/* testing routes for new features and stuff */}
              <Route
                path='/test-ground'
                element={
                  <ProtectedRoute>
                    <TestGround />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Layout>
        </AuthProvider>
      </div>
    </ThemeProvider>
  );
};

export default App;
