import styles from '../agent.module.css';

const Feature = ({ title, icon, isActive, isComingSoon }) => {
  return (
    <div
      className={`${styles.feature} ${isActive ? styles['feature--active'] : styles['feature--disabled']}`}
    >
      <div className={styles['feature__content']}>
        {isComingSoon && (
          <div className={styles['feature__coming-soon']}>Coming Soon</div>
        )}
        {icon}
        <h3
          className={`${styles['feature__title']} ${isActive ? styles['feature__title--active'] : ''}`}
        >
          {title}
        </h3>
      </div>
    </div>
  );
};

export default Feature;
